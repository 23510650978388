import { useAuth } from "auth/use-auth";
import Button from "@mui/material/Button";

export const UserSignOutButton = () => {
  const { handleSignOut } = useAuth();

  const handleSignOutClick = () => {
    handleSignOut();
  };

  return (
    <Button onClick={handleSignOutClick}>Sign Out</Button>
  );
};
