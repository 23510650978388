import { useMutation } from "@tanstack/react-query";
import { putFestivalEditionArtistAttended } from "api/festival_edition/festival_edition";

export const usePutFestivalEditionArtistAttended = () => {
  return useMutation({
    mutationFn: ({ artist, attended, authToken, festivalEdition }) =>
      putFestivalEditionArtistAttended({
        artist,
        attended,
        authToken,
        festivalEdition,
      }).then((response) => response.data),
    onSuccess: (data) => console.log({ data }),
  });
};
