import ErrorPage from "views/error/error_page";
import { Layout } from "views/layout/layout";
import { User, loader as loaderUser } from "views/user/user";
import { Settings, loader as loaderSettings } from 'views/user/settings/settings';
import { action as actionSetUsername } from 'views/user/settings/components/set-username/set-username';

export const routesUser = ({ queryClient, user }) => {
  return [
    {
      action: actionSetUsername({ queryClient, user }),
      path: "/user/settings",
      element: (
        <Layout>
          <Settings />
        </Layout>
      ),
      errorElement: <ErrorPage />,
      loader: loaderSettings({ queryClient, user }),
    },
    {
      action: actionSetUsername({ queryClient, user }),
      path: "/user/:slug",
      element: (
        <Layout>
          <User />
        </Layout>
      ),
      errorElement: <ErrorPage />,
      loader: loaderUser({ queryClient, user }),
    },
  ];
};
