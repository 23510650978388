import Grid from "@mui/material/Grid";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";

export const ListOptions = {
  Grid: "Grid",
  List: "List",
};

export const GridOrList = ({ list, setList }) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <GridViewIcon
          onClick={() => setList(ListOptions.Grid)}
          sx={{ color: list === ListOptions.Grid ? "black" : "gray", cursor: 'pointer' }}
        />
      </Grid>

      <Grid item>
        <ViewListIcon
          onClick={() => setList(ListOptions.List)}
          sx={{ color: list === ListOptions.List ? "black" : "gray", cursor: 'pointer' }}
        />
      </Grid>
    </Grid>
  );
};
