import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "auth/use-auth";
import Artist, { loader as loaderArtist } from "../views/artist/artist";
import Artists, { loader as loaderArtists } from "../views/artists/artists";
import Festivals, {
  loader as loaderFestivals,
} from "../views/festivals/festivals";
import FestivalRouter, {
  loader as loaderFestivalRouter,
} from "../views/festival/components/festival-router/festival-router";
import Index, { loader as loaderIndex } from "../views/index/index";
import ErrorPage from "../views/error/error_page";
import { Layout } from "../views/layout/layout";
import { routesAuth } from "./auth/routes-auth";
import { routesUser } from "./user/routes-user";

import { Festival, loader as loaderFestival } from "views/festival/festival";
import {
  FestivalEdition,
  loader as loaderFestivalEdition,
} from "views/festival-edition/festival-edition";

export const Router = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Index />
        </Layout>
      ),
      errorElement: <ErrorPage />,
      loader: loaderIndex({ queryClient, user }),
    },
    /*{
      path: "/festivals",
      element: (
        <Layout>
          <Festivals />
        </Layout>
      ),
      errorElement: <ErrorPage />,
      loader: loaderFestivals({ queryClient, user }),
    },*/
    {
      path: "/festival/:slug/*",
      element: (
        <Layout>
          <FestivalEdition />
        </Layout>
      ),
      errorElement: <ErrorPage />,
      loader: loaderFestivalEdition({ queryClient, user }),
    },
    {
      path: "/festival/:slug",
      element: (
        <Layout>
          <Festival />
        </Layout>
      ),
      errorElement: <ErrorPage />,
      loader: loaderFestival({ queryClient, user }),
    },
    /*{
      path: "/artists",
      element: (
        <Layout>
          <Artists />
        </Layout>
      ),
      loader: loaderArtists({ queryClient, user }),
    },*/
    {
      path: "/artist/:slug",
      element: (
        <Layout>
          <Artist />
        </Layout>
      ),
      errorElement: <ErrorPage />,
      loader: loaderArtist(queryClient),
    },
    ...routesAuth,
    ...routesUser({ queryClient, user }),
  ]);

  return (
    <>
      <RouterProvider router={router} />
      {/*<ReactQueryDevtools position="bottom-right" />*/}
    </>
  );
};
