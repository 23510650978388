import { useAuth } from "auth/use-auth";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';

import { sha256 } from 'js-sha256';
import { queryUser } from "api/query/query-user";
import { queryUserSettings } from "api/query/query-user-settings";

import { Helmet } from "react-helmet";
import { UserHeader } from "views/user/components/user-header/user-header";

import { GravatarProfile } from 'views/user/settings/components/gravatar-profile/gravatar-profile';
import { GravatarQuickEdit } from 'views/user/settings/components/gravatar-quick-edit/gravatar-quick-edit';
import { SetUsername } from 'views/user/settings/components/set-username/set-username';

export const loader =
  ({ queryClient, user }) =>
  async ({ params }) => {
    if (!user) {
      return null;
    }

    const query = queryUserSettings({ user });

    return queryClient.ensureQueryData(query);
  };

export const Settings = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { data } = useQuery(queryUserSettings({ user }));

  const email_sha256 = user?.email ? sha256(user?.email) : undefined;

  const { data: userData } = useQuery(queryUser({ slug: email_sha256, user }));

  const metaTitle = `Settings | mfests.com - music festivals`;
  const metaDescription = `mfests.com user settings`;

  if (!user || !data || !userData) {
    return navigate('/');
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
      </Helmet>

      <UserHeader user={userData} />
      <SetUsername user={data} />
      <GravatarQuickEdit />
      <GravatarProfile />
    </>
  );
};
