import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ModalImage from "react-modal-image";

export const FestivalPoster = ({ edition, zoom }) => {
  const alt = `${edition.name} Festival Poster`;
  const src = `/images/posters/${edition.poster}`;

  if (!edition.poster) {
    return null;
  }

  if (zoom) {
    return <ModalImage alt={alt} small={src} large={src} />;
  }

  return (
    <>
      <LazyLoadImage
        alt={alt}
        effect="blur"
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          // style: { transitionDelay: "1s" },
        }}
        src={src}
        width="100%"
      />
    </>
  );
};
