import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FestivalEditionActions } from "views/components/festival-edition-actions/festival-edition-actions";

import { paginate } from "utils/pagination/paginate";

import { Link } from "react-router-dom";

import { FestivalPoster } from "views/components/festival-poster/festival-poster";

import { getDateSpanString } from "utils/dates/get-date-span-string";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);

export const FestivalsRow = ({
  center,
  fadeAttendedFestivals,
  festivals,
  pagination,
  perRow = 3,
  showActions,
  showDates,
  showFestivalImage,
  showMonthDividers,
  showYearDividers,
  showUpcomingDates,
}) => {
  const listRef = useRef(null);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pagination ?? 100);

  const pageCount = Math.ceil(festivals.length / pageSize);

  const today = dayjs();

  let monthYearCounter = 0;
  let previousFestivalDate;

  const handlePaginationChange = (event, value) => {
    setPage(value);

    window.scrollTo(0, listRef.current.offsetTop);
  };

  if (!festivals || !festivals.length) {
    return;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={center ? "center" : ""}
        ref={listRef}
      >
        {paginate({
          array: festivals,
          page_size: pageSize,
          page_number: page,
        })?.map((edition) => {
          const isAttendedFestival = edition.user_attended_festival_edition;
          const isUpcomingEdition = today.isSameOrBefore(edition.end_date);
          const isUpcomingEditionStartDate = today.isSameOrBefore(
            edition.start_date,
          );

          const dayjsEditionStartDate = dayjs(edition.start_date);

          const isNewYearDivider =
            showYearDividers &&
            dayjsEditionStartDate.year() !== previousFestivalDate?.year();

          const isNewMonthDivider =
            showMonthDividers &&
            dayjsEditionStartDate.month() !== previousFestivalDate?.month();

          previousFestivalDate = dayjsEditionStartDate;

          const poster = (
            <Link to={`/festival/${edition.slug}`}>
              <Box
                sx={
                  fadeAttendedFestivals &&
                  isAttendedFestival &&
                  !isUpcomingEditionStartDate && {
                    opacity: 0.7,
                    filter: "grayscale(100%)",
                  }
                }
              >
                {edition.poster && <FestivalPoster edition={edition} />}
                {!edition.poster &&
                  showFestivalImage &&
                  edition.festival_image && (
                    <LazyLoadImage
                      alt={edition.name}
                      effect="blur"
                      wrapperProps={
                        {
                          // If you need to, you can tweak the effect transition using the wrapper style.
                          // style: { transitionDelay: "1s" },
                        }
                      }
                      src={`/images/festivals/${edition.festival_image}`}
                      width="100%"
                    />
                  )}
              </Box>
            </Link>
          );

          return (
            <>
              {isNewYearDivider && (
                <Grid item xs={12}>
                  <Divider textAlign="left">
                    {dayjsEditionStartDate.year()}
                  </Divider>
                </Grid>
              )}
              {isNewMonthDivider && (
                <Grid item xs={12}>
                  <Divider textAlign="left">
                    {dayjs.months()[dayjsEditionStartDate.month()]}
                  </Divider>
                </Grid>
              )}

              <Grid item key={edition.slug} xs={12} sm={12 / perRow}>
                <Box mb={5}>
                  <Link to={`/festival/${edition.slug}`}>
                    <Box textAlign="center">
                      <Typography variant="subtitle1">
                        {edition.name}
                      </Typography>
                    </Box>

                    {(showDates ||
                      (showUpcomingDates && isUpcomingEdition)) && (
                      <Box textAlign="center">{getDateSpanString(edition)}</Box>
                    )}
                  </Link>

                  <Box mt={1}>
                    {showActions ? (
                      <FestivalEditionActions festival_edition={edition}>
                        {poster}
                      </FestivalEditionActions>
                    ) : (
                      poster
                    )}
                  </Box>
                </Box>
              </Grid>
            </>
          );
        })}
      </Grid>

      {pageCount > 1 && (
        <Stack alignItems="center">
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePaginationChange}
          />
        </Stack>
      )}
    </>
  );
};
