import { mfestsApi } from "api/mfests_api";

export const getArtist = async ({ authToken, slug }) => {
  const data = await mfestsApi.get({ authToken, url: `artist/${slug}` });
  return data.data[0];
};

export const putArtistAttended = async ({ attended, authToken, slug }) => {
  const data = await mfestsApi.put({
    authToken,
    body: { attended },
    url: `artist/${slug}/attended`,
  });
  return data.data[0];
};

export const putArtistFavorite = async ({ add, authToken, slug }) => {
  const data = await mfestsApi.put({
    authToken,
    body: { add },
    url: `artist/${slug}/favorite`,
  });
  return data.data[0];
};

export const putArtistWatchlist = async ({ add, authToken, slug }) => {
  const data = await mfestsApi.put({
    authToken,
    body: { add },
    url: `artist/${slug}/watchlist`,
  });
  return data.data[0];
};
