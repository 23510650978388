import { useState } from 'react';
import { getAuth, confirmPasswordReset } from "firebase/auth";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export const ForgotPasswordReset = () => {
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const params = useParams();
	const authentication = getAuth();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const oobCode = params.code;
		const newPassword = event.target.password.value;

		try {
			confirmPasswordReset(authentication, oobCode, newPassword).then(
				() => {
					setShowSuccessMessage(true);
				},
			);
		} catch (error) {
			setShowErrorMessage(true);
		}
	};

	return (
		<form id="sign-up" onSubmit={handleSubmit}>
			{showErrorMessage && (
        <Box mb={1}>
          <Alert severity="error">There was an error, please try again.</Alert>
        </Box>
      )}

      {showSuccessMessage && (
        <Box mb={1}>
          <Alert severity="success">Password reset. You can now sign in.</Alert>
        </Box>
      )}

			<Box display="inline" mr={2}>
				<TextField
					label="New Password"
					name="password"
					type="password"
					size="small"
					sx={{ maxWidth: 100 }}
					variant="standard"
					required
				/>
			</Box>

			<Button type="submit">Reset Password</Button>
		</form>
	);
};
