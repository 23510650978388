import { useAuth } from "auth/use-auth";
import { GravatarQuickEditorCore } from "@gravatar-com/quick-editor";

export const GravatarQuickEdit = ({ children }) => {
  const { user } = useAuth();

  const quickEditorCore = new GravatarQuickEditorCore({
    email: user?.email,
    scope: ["avatars", "privacy"],
    onProfileUpdated: () => {
      // console.log("Profile updated!");
    },
    onOpened: () => {
      // console.log("Editor opened!");
    },
  });

  const handleOnClick = () => {
    quickEditorCore.open();
  };

  return <a onClick={handleOnClick}>{children ? children : "edit"}</a>;
};
