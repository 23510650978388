import { useAuth } from "auth/use-auth";
import { useQueryClient } from "@tanstack/react-query";
import { sha256 } from "js-sha256";

import Button from "@mui/material/Button";

import { usePutUserFollow } from "api/query/put/use-put-user-follow";

export const UserFollowButton = ({ userData }) => {
  const { authToken, noAuthDialog, user } = useAuth();
  const queryClient = useQueryClient();

  const email_sha256 = user?.email ? sha256(user?.email) : undefined;

  const isFollowing =
    userData.followers?.find((user) => user.email_sha256 === email_sha256) !==
    undefined;

  const { mutate: userFollow } = usePutUserFollow();

  const handleToggleUserFollow = () => {
    if (!authToken) {
      return noAuthDialog.setShowNoAuthDialog(true);
    }

    userFollow(
      {
        follow: !isFollowing,
        authToken,
        user: userData.email_sha256,
      },
      {
        onSettled: () => {
          queryClient.refetchQueries({
            queryKey: ["user", userData.username || userData.email_sha256, user?.uid],
          });
        },
      },
    );
  };

  // don't show the button if the current user is the logged in user
  if (email_sha256 === userData.email_sha256) {
    return null;
  }

  return (
    <Button
      onClick={handleToggleUserFollow}
      variant={isFollowing ? "outlined" : "contained"}
    >
      {isFollowing ? "Following" : "Follow"}
    </Button>
  );
};
