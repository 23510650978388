import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { UserRow } from "views/festival-edition/components/user-row/user-row";

export const ArtistFollowCount = ({ artist }) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={3}
    >
      {artist.editions.length > 0 && (
        <Stack direction="column">
          Festivals
          <br />
          {artist.editions.length}
        </Stack>
      )}

      {artist.artist_seen?.length > 0 && (
        <Stack direction="column">
          Seen
          <br />
          <UserRow
            max={5}
            size="small"
            users={artist.artist_seen}
            usersCount={artist.artist_seen?.length}
          />
        </Stack>
      )}

      {artist.artist_favorite?.length > 0 && (
        <Stack direction="column">
          Favorite
          <br />
          <UserRow
            max={5}
            size="small"
            users={artist.artist_favorite}
            usersCount={artist.artist_favorite?.length}
          />
        </Stack>
      )}

      {artist.artist_watchlist?.length > 0 && (
        <Stack direction="column">
          Watchlist
          <br />
          <UserRow
            max={5}
            size="small"
            users={artist.artist_watchlist}
            usersCount={artist.artist_watchlist?.length}
          />
        </Stack>
      )}
    </Stack>
  );
};
