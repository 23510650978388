import { useMutation } from "@tanstack/react-query";
import { putArtistAttended } from "api/artist/artist";

export const usePutArtistAttended = () => {
  return useMutation({
    mutationFn: ({ attended, authToken, slug }) =>
      putArtistAttended({ attended, authToken, slug }).then(
        (response) => response.data,
      ),
    onSuccess: (data) => console.log({ data }),
  });
};
