import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { Search } from 'views/layout/components/search/search';
import { UserMenu } from 'views/layout/components/header/components/user-menu/user-menu';

export const Header = () => {
  return (
    <Box mt={1} mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Link to={`/`}>
            <Typography variant="h5">mfests.</Typography>
          </Link>
        </Grid>

        <Grid item xs={8}>
          <Box mt={0.5}>
            <Search />
          </Box>
        </Grid>

        <Grid item>
          <Box mt={0.5}>
            <UserMenu />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
