import { useLoaderData, Form } from "react-router-dom";
import { putUsername } from "api/user/user-settings";
import {
  getAuth,
  updateProfile,
} from "firebase/auth";

export const action =
  ({ queryClient, user }) =>
  async ({ request, params }) => {
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    await updateProfile(user.auth.currentUser, { displayName: updates.username });
    await putUsername({ ...updates, authToken: user?.accessToken });
    await queryClient.invalidateQueries({ queryKey: ["user-settings"] });

    return null;
  };

export const SetUsername = ({ setShowSetUsername, showCancelButton, user }) => {
  return (
    <Form method="put" id="set-username">
      <input
        placeholder="Choose a Username"
        aria-label="Username"
        type="text"
        name="username"
        defaultValue={user?.username}
      />

      <button type="submit">Save</button>
      {setShowSetUsername && showCancelButton && (
        <button onClick={() => setShowSetUsername(false)} type="cancel">
          Cancel
        </button>
      )}
    </Form>
  );
};
