import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { Link } from "react-router-dom";

export const FestivalEditionLinks = ({ festivalName, festivalEditions }) => {
  return (
    <Box width="100%" sx={{ whiteSpace: "nowrap" }}>
      <Box component="div" sx={{ overflow: "auto" }}>
        <Stack direction="row" spacing={2.5}>
          {festivalEditions?.map((edition) => (
            <Link to={`/festival/${edition.slug}`} key={edition.slug}>
              {edition.name.replace(`${festivalName} `, "")}
            </Link>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
