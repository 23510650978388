import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";

export const UserAvatar = ({ noLink, size, user }) => {
  let width = 40;
  let height = 40;

  switch (size) {
    case "small":
      width = 24;
      height = 24;
      break;
    case "large":
      width = 100;
      height = 100;
      break;
  }

  const getAvatar = (user) => {
    if (!user?.email_sha256) {
      return <Avatar alt={user?.username} sx={{ width, height }} />;
    }

    return (
      <Avatar
        alt={user.username}
        src={`https://www.gravatar.com/avatar/${user.email_sha256}?d=mp`}
        sx={{ width, height }}
      />
    );
  };

  if (noLink || !user.email_sha256) {
    return getAvatar(user);
  }

  return (
    <Link to={`/user/${user.username ? user.username : user.email_sha256}`}>
      {getAvatar(user)}
    </Link>
  );
};
