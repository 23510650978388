import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SetUsername } from "views/user/settings/components/set-username/set-username";
import { GravatarQuickEdit } from "views/user/settings/components/gravatar-quick-edit/gravatar-quick-edit";
import { UserSignOutButton } from "views/user/components/user-header/components/user-sign-out-button/user-sign-out-button";

import { UserContext } from "views/user/user";

export const UserUsername = ({ user }) => {
  const userContext = useContext(UserContext);

  const hasUsername = !!user.username;

  const [showSetUsername, setShowSetUsername] = useState(!hasUsername);

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs>
        <Grid container spacing={2}>
          {hasUsername && <Grid item>{user.username}</Grid>}

          <Grid item>
            {!showSetUsername && userContext.isAuthUser && (
              <a onClick={() => setShowSetUsername(true)}>change username</a>
            )}
            {showSetUsername && userContext.isAuthUser && (
              <SetUsername
                setShowSetUsername={setShowSetUsername}
                showCancelButton={hasUsername}
              />
            )}
          </Grid>

          {userContext.isAuthUser && (
            <Grid item>
              <GravatarQuickEdit>change profile picture</GravatarQuickEdit>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4}><Box textAlign="right">{userContext.isAuthUser && <UserSignOutButton />}</Box></Grid>
    </Grid>
  );
};
