import { useContext } from "react";
import { useAuth } from "auth/use-auth";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queryArtist } from "api/query/query-artist";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Helmet } from "react-helmet";

import { ArtistImage } from "views/artist/components/artist-image/artist-image";
import { ArtistUserActions } from "views/components/user-actions/artist-user-actions/artist-user-actions";
import { ArtistFollowCount } from "views/artist/components/artist-follow-count/artist-follow-count";
import { FestivalsRow } from "views/components/festivals-row/festivals-row";

import { LayoutContext } from "views/layout/layout";

export const loader =
  (queryClient) =>
  async ({ params }) => {
    const query = queryArtist({ slug: params.slug });

    return queryClient.ensureQueryData(query);
  };

export default function Artist() {
  const { user } = useAuth();
  const params = useParams();
  const { data: artist } = useQuery(queryArtist({ slug: params.slug, user }));

  const layoutContext = useContext(LayoutContext);

  const musicbrainzGenres = artist?.services?.musicbrainz?.genres
    ?.sort((a, b) => b.count - a.count)
    .slice(0, 3);
  const spotifyGenres = artist?.services?.spotify?.genres;

  // for now, let's not show act names and remove duplicate artists
  const festivals = artist?.editions.filter(
    (obj, index, self) => index === self.findIndex((o) => obj.slug === o.slug),
  );

  if (!artist) {
    return null;
  }

  const metaTitle = `${artist.name} | mfests.com - music festivals`;
  const metaDescription = `${artist.name} music festivals`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
      </Helmet>

      <Box mb={3}>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
        >
          <Stack alignItems="flex-start" direction="row">
            <Box
              mr={layoutContext.isMobile ? 3 : 5}
              width={layoutContext.isMobile ? 100 : 150}
            >
              <ArtistImage artist={artist} />
            </Box>

            <Stack direction="column">
              <Typography variant="h4">{artist.name}</Typography>
              <Typography variant="caption">
                <i>
                  {musicbrainzGenres?.length > 0
                    ? musicbrainzGenres?.map((genre, i) => {
                        return (
                          genre.name +
                          (i === musicbrainzGenres.length - 1 ? "" : ", ")
                        );
                      })
                    : spotifyGenres?.map((genre, i) => {
                        return (
                          genre + (i === spotifyGenres.length - 1 ? "" : ", ")
                        );
                      })}
                </i>
              </Typography>

              {layoutContext.isMobile && <ArtistUserActions artist={artist} />}
              {!layoutContext.isMobile && (
                <Box mt={2} mb={5}>
                  <ArtistFollowCount artist={artist} />
                </Box>
              )}
            </Stack>
          </Stack>
          {!layoutContext.isMobile && <ArtistUserActions artist={artist} />}
        </Stack>
      </Box>

      {layoutContext.isMobile && (
        <Box mb={5}>
          <ArtistFollowCount artist={artist} />
        </Box>
      )}

      <FestivalsRow
        festivals={festivals}
        pagination={9}
        showActions
        showFestivalImage
        showMonthDividers
        showYearDividers
      />
    </>
  );
}
