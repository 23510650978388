import { useMutation } from "@tanstack/react-query";
import { putArtistFavorite } from "api/artist/artist";

export const usePutArtistFavorite = () => {
  return useMutation({
    mutationFn: ({ add, authToken, slug }) =>
      putArtistFavorite({ add, authToken, slug }).then(
        (response) => response.data,
      ),
    onSuccess: (data) => console.log({ data }),
  });
};
