import { useMutation } from "@tanstack/react-query";
import { putUserFollow } from "api/user/user";

export const usePutUserFollow = () => {
  return useMutation({
    mutationFn: ({ follow, authToken, user }) =>
      putUserFollow({ follow, authToken, user }).then(
        (response) => response.data,
      ),
    onSuccess: (data) => console.log({ data }),
  });
};
