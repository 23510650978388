import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { FestivalsRow } from "views/components/festivals-row/festivals-row";

import { UserContext } from "views/user/user";

export const UserFestivals = ({ festivals }) => {
  const userContext = useContext(UserContext);

  const [festivalsToDisplay, setFestivalsToDisplay] = useState(festivals);
  const [festivalFilter, setFestivalFilter] = useState("all");
  const [fadeAttendedFestivals, setFadeAttendedFestivals] = useState(false);

  const mutualFestivals = festivals.filter(
    (fe) => fe.user_attended_festival_edition,
  );

  const withoutMeFestivals = festivals.filter(
    (fe) => !fe.user_attended_festival_edition,
  );

  useEffect(() => {
    switch (festivalFilter) {
      case "all":
        setFestivalsToDisplay(festivals);
        break;
      case "mutual":
        setFestivalsToDisplay(mutualFestivals);
        break;
      case "without-me":
        setFestivalsToDisplay(withoutMeFestivals);
        break;
    }
  }, [festivalFilter]);

  if (!festivals || festivals.length === 0) {
    return <>No Festivals Added!</>;
  }

  return (
    <>
      {userContext.isUserSignedIn && !userContext.isAuthUser && (
        <Box mb={3}>
          <Grid container justifyContent="space-between">
            {mutualFestivals.length > 0 && (
              <Grid item>
                <>You were at {mutualFestivals.length} festivals together!</>
              </Grid>
            )}

            <Grid item xs>
              <Box textAlign="right">
                <ButtonGroup size="small">
                  <Button
                    onClick={() => setFestivalFilter("all")}
                    variant={
                      festivalFilter === "all" ? "contained" : "outlined"
                    }
                  >
                    All
                  </Button>
                  <Button
                    onClick={() => setFestivalFilter("mutual")}
                    variant={
                      festivalFilter === "mutual" ? "contained" : "outlined"
                    }
                  >
                    {mutualFestivals.length > 0 && mutualFestivals.length}{" "}
                    Mutual
                  </Button>
                  <Button
                    onClick={() => setFestivalFilter("without-me")}
                    variant={
                      festivalFilter === "without-me" ? "contained" : "outlined"
                    }
                  >
                    {withoutMeFestivals.length > 0 && withoutMeFestivals.length}{" "}
                    Without Me
                  </Button>
                </ButtonGroup>

                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fadeAttendedFestivals}
                        onChange={() =>
                          setFadeAttendedFestivals(!fadeAttendedFestivals)
                        }
                      />
                    }
                    label="Fade Out Festivals I've Been To"
                    size="small"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <FestivalsRow
        fadeAttendedFestivals={fadeAttendedFestivals}
        festivals={festivalsToDisplay}
        pagination={12}
        perRow={4}
        showActions
        showFestivalImage
        showYearDividers
        showUpcomingDates
      />
    </>
  );
};
