import { useEffect, useState } from "react";

import { useAuth } from "auth/use-auth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

import { GoogleButton } from "views/auth/dialog-no-auth/components/google-button/google-button";

import { createUser } from "api/user/user";

import { Link } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export const DialogNoAuth = () => {
  const authentication = getAuth();
  const provider = new GoogleAuthProvider();
  provider.addScope("email");

  const { authToken, noAuthDialog, handleSignIn } = useAuth();
  const [showSignIn, setShowSignIn] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showInitialScreen, setShowInitialScreen] = useState(true);

  const signInWithGoogle = () => {
    signInWithPopup(authentication, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        if (result.user) {
          createUser({
            email: result.user.email,
            firebase_uid: result.user.uid,
          });
          handleSignIn({ user: result.user });
        }
      })
      .catch((error) => {
        console.log({ error });
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        showError(error);
      });
  };

  const toggleSignIn = ({ signIn, showError }) => {
    if (!showError) {
      setShowErrorMessage(false);
    }

    setShowSignIn(signIn);
  };

  const signIn = async ({ email, password }) => {
    try {
      await signInWithEmailAndPassword(authentication, email, password).then(
        (response) => {
          handleSignIn({ user: response?.user });
        },
      );
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        showError({ error });
      }
    }
  };

  const handleSignInSwitch = ({ email, password }) => {
    setShowErrorMessage(false);
    setShowSignIn(true);
    signIn({ email, password });
  };

  const showError = ({ error, email, password }) => {
    switch (error?.code) {
      case "auth/email-already-in-use":
        setShowErrorMessage(
          <>
            That email is already signed up. Did you mean to{" "}
            <Box
              display="inline"
              onClick={() => handleSignInSwitch({ email, password })}
              sx={{ cursor: "pointer" }}
            >
              <u>sign in?</u>
            </Box>
          </>,
        );
        break;
      case "auth/invalid-credential":
        setShowErrorMessage(
          "That's not your password. Do you need to reset it?",
        );
        break;
      case "auth/popup-closed-by-user":
        setShowErrorMessage(false);
        break;
      default:
        setShowErrorMessage("An error occured, please try again.");
    }
  };

  const handleClose = () => {
    noAuthDialog.setShowNoAuthDialog(false);
    setShowInitialScreen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;

    if (showSignIn) {
      signIn({ email, password });
    } else {
      try {
        await createUserWithEmailAndPassword(
          authentication,
          email,
          password,
        ).then((userCredential) => {
          if (userCredential.user) {
            createUser({
              email: userCredential.user.email,
              firebase_uid: userCredential.user.uid,
            });
            handleSignIn({ user: userCredential.user });
          }
        });
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          showError({ error, email, password });
        }
      }
    }
  };

  useEffect(() => {
    if (authToken) {
      noAuthDialog.setShowNoAuthDialog(false);
    }
  }, [authToken]);

  const EmailPassword = () => {
    return (
      <>
        <Box px={10}>
          {showErrorMessage && (
            <Box mb={1}>
              <Alert severity="error">{showErrorMessage}</Alert>
            </Box>
          )}

          <Box onClick={signInWithGoogle} textAlign="center">
            <GoogleButton />
          </Box>

          <TextField
            label="Email"
            name="email"
            type="email"
            size="small"
            variant="standard"
            fullWidth
            required
          />

          <TextField
            label="Password"
            name="password"
            type="password"
            size="small"
            variant="standard"
            fullWidth
            required
            inputProps={{ minLength: 6 }}
          />
        </Box>
      </>
    );
  };

  const signInDialog = (
    <>
      <DialogTitle>Welcome Back!</DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <EmailPassword />
        </Box>

        <Box mb={4} textAlign="center">
          <Link to="/forgot-password" onClick={handleClose}>
            Forgot your password?
          </Link>
        </Box>

        <Box textAlign="center">
          <Box
            display="inline-block"
            onClick={() => toggleSignIn({ signIn: false, showError: false })}
            sx={{ cursor: "pointer" }}
          >
            Don't have an account yet? <u>Sign Up!</u>
          </Box>
        </Box>
      </DialogContent>
    </>
  );

  const signUpDialog = (
    <>
      <DialogTitle>Sign Up to Continue!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To keep track of which festivals and artists you've seen (or want to
          see!), sign up now!
        </DialogContentText>

        <Box mt={2} mb={7}>
          <EmailPassword />
        </Box>

        <Box textAlign="center">
          <Box
            display="inline-block"
            onClick={() => toggleSignIn({ signIn: true, showError: false })}
            sx={{ cursor: "pointer" }}
          >
            Already have an account? <u>Sign In!</u>
          </Box>
        </Box>
      </DialogContent>
    </>
  );

  const initialScreen = (
    <>
      <DialogTitle>Sign Up to Continue!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To keep track of which festivals and artists you've seen (or want to
          see!), sign up now!
        </DialogContentText>

        <Box mt={2} mb={7}>
          <Box mb={3} onClick={signInWithGoogle} textAlign="center">
            <GoogleButton />
          </Box>

          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Button
                onClick={() => {
                  setShowSignIn(true);
                  setShowInitialScreen(false);
                }}
                variant="contained"
              >
                Sign in with Email
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={() => {
                  setShowSignIn(false);
                  setShowInitialScreen(false);
                }}
                variant="contained"
              >
                Sign up with Email
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </>
  );

  if (authToken) {
    return null;
  }

  return (
    <Dialog open={noAuthDialog.showNoAuthDialog} onClose={handleClose}>
      {showInitialScreen ? (
        initialScreen
      ) : (
        <form onSubmit={handleSubmit}>
          {showSignIn ? signInDialog : signUpDialog}

          <DialogActions>
            <Box mr={3}>
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
            <Button variant="contained" type="submit">
              {showSignIn ? "Sign In" : "Sign Up"}
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};
