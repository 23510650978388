import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import BeenhereIcon from "@mui/icons-material/Beenhere";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FavoriteIcon from "@mui/icons-material/Favorite";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

export const ActionButtonVariant = {
  Artist: "Artist",
  Festival: "Festival",
  FestivalEdition: "FestivalEdition",
  Watchlist: "Watchlist",
};

export const ActionButton = ({ on, onClick, tooltipTitle, variant }) => {
  let icon;
  let tooltipTitleToShow;

  switch (variant) {
    case ActionButtonVariant.Artist:
      icon = on ? (
        <CheckCircleIcon />
      ) : (
        <VisibilityIcon sx={{ color: "gray" }} />
      );
      tooltipTitleToShow = "Seen";
      break;
    case ActionButtonVariant.Favorite:
      icon = on ? (
        <FavoriteIcon sx={{ color: "red" }} />
      ) : (
        <FavoriteIcon sx={{ color: "gray" }} />
      );
      tooltipTitleToShow = "Favorite";
      break;
    case ActionButtonVariant.Festival:
      icon = <BeenhereIcon />;
      break;
    case ActionButtonVariant.FestivalEdition:
      icon = on ? <EventAvailableIcon /> : <CalendarTodayIcon />;
      break;
    case ActionButtonVariant.FestivalEditionArtist:
      icon = on ? <PlaylistAddCheckIcon /> : <PlaylistAddIcon />;
      break;
    case ActionButtonVariant.Watchlist:
      icon = on ? (
        <WatchLaterIcon />
      ) : (
        <WatchLaterIcon sx={{ color: "gray" }} />
      );
      tooltipTitleToShow = "Watchlist";
      break;
  }

  if (tooltipTitle) {
    tooltipTitleToShow = tooltipTitle;
  }

  return (
    <Tooltip title={tooltipTitleToShow}>
      <IconButton
        color="inherit"
        onClick={onClick}
        sx={{
          opacity: on ? 1 : 0.2,

          "&:hover": {
            opacity: 1,
          },
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};
