import { LazyLoadImage } from "react-lazy-load-image-component";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Link } from "react-router-dom";

export const FestivalImage = ({ festival, link }) => {
  const size = 100;
  const scale = 1.5;

  let festivalImageSrc;

  if (festival?.image) {
    festivalImageSrc = `/images/festivals/${festival?.image}`;
  } else {
    const latestPoster = festival.editions.find((edition) => edition.poster)?.poster;

    if (latestPoster) {
      festivalImageSrc = `/images/posters/${latestPoster}`;
    }
  }

  const festivalImage = (
    <LazyLoadImage
      alt={festival.name}
      css={css`
        aspect-ratio: 1;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        height: auto;
        object-fit: cover;
        max-width: 100%;
      `}
      height={size}
      src={festivalImageSrc}
      width={size}
    />
  );

  if (!festivalImageSrc) {
    return null;
  }

  return (
    <>
      {link ? (
        <Link to={`/festival/${festival?.slug}`}>{festivalImage}</Link>
      ) : (
        festivalImage
      )}
    </>
  );
};
