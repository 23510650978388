import { useMutation } from "@tanstack/react-query";
import { putFestivalEditionAttended } from "api/festival_edition/festival_edition";

export const usePutFestivalEditionAttended = () => {
  return useMutation({
    mutationFn: ({ attended, authToken, slug }) =>
      putFestivalEditionAttended({ attended, authToken, slug }).then(
        (response) => response.data,
      ),
    onSuccess: (data) => console.log({ data }),
  });
};
