export const mfestsApi = {
  get,
  post,
  put,
  delete: _delete,
};

// const baseUrl = "http://localhost:8080/";
const baseUrl = "https://api.mfests.com/";

function get({ authToken, url }) {
  const requestOptions = {
    method: "GET",
  };

  if (authToken) {
    requestOptions.headers = {
      Authorization: `Bearer ${authToken}`,
    };
  }

  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

function post({ authToken, url, body }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  if (authToken) {
    requestOptions.headers = {
      Authorization: `Bearer ${authToken}`,
    };
  }

  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

function put({ authToken, body, url }) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  if (authToken) {
    requestOptions.headers.Authorization = `Bearer ${authToken}`;
  }

  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
  };
  return fetch(baseUrl + url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
