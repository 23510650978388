import { LazyLoadImage } from "react-lazy-load-image-component";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ArtistImage = ({ artist }) => {
  if (!artist.services?.spotify?.images) {
    return null;
  }

  const size = 200;
  const scale = 1.5;
  const reversed = [...artist.services?.spotify?.images].reverse();
  const artistImage = reversed.find((img) => img.width >= size);

  if (!artistImage) {
    return null;
  }

  return (
    <LazyLoadImage
      alt={artist.name}
      css={css`
        aspect-ratio: 1;
        border-radius: 50%;
        height: auto;
        object-fit: cover;
        width: 100%;
      `}
      height={size}
      src={artistImage.url}
      width={size}
    />
  );
};
