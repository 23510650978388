import { useMutation } from "@tanstack/react-query";
import { putArtistWatchlist } from "api/artist/artist";

export const usePutArtistWatchlist = () => {
  return useMutation({
    mutationFn: ({ add, authToken, slug }) =>
      putArtistWatchlist({ add, authToken, slug }).then(
        (response) => response.data,
      ),
    onSuccess: (data) => console.log({ data }),
  });
};
