import { mfestsApi } from "api/mfests_api";

export const getFestivalEdition = async ({ authToken, slug }) => {
  const data = await mfestsApi.get({
    authToken,
    url: `festival_edition/${slug}`,
  });
  return data.data[0];
};

export const putFestivalEditionAttended = async ({ attended, authToken, slug }) => {
  const data = await mfestsApi.put({
    authToken,
    body: { attended },
    url: `festival_edition/${slug}/attended`,
  });
  return data.data[0];
};

export const putFestivalEditionArtistAttended = async ({ artist, attended, authToken, festivalEdition }) => {
  const data = await mfestsApi.put({
    authToken,
    body: { artist, attended },
    url: `festival_edition/${festivalEdition}/artist/attended`,
  });
  return data.data[0];
};
