import { createContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { ScrollToTop } from "./components/scroll-to-top/scroll-to-top";
import { Header } from "./components/header/header";

import { DialogNoAuth } from "views/auth/dialog-no-auth/dialog-no-auth";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const LayoutContext = createContext();

export const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ScrollToTop>
      <CssBaseline />
      <LayoutContext.Provider value={{ isMobile }}>
        <DialogNoAuth />
        <Container disableGutters maxWidth="md">
          <Box mx={1}>
            <Header />
            {children}
          </Box>
        </Container>
      </LayoutContext.Provider>
    </ScrollToTop>
  );
};
