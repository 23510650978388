import { useAuth } from "auth/use-auth";
import { useQueryClient } from "@tanstack/react-query";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

import { usePutFestivalEditionAttended } from "api/query/put/use-put-festival-edition-attended";

export const FestivalEditionActions = ({ children, festival_edition }) => {
  const { authToken, noAuthDialog, user } = useAuth();
  const queryClient = useQueryClient();

  const { mutate: festivalEditionAttended } = usePutFestivalEditionAttended();

  const handleToggleFestivalEditionAttended = () => {
    if (!authToken) {
     return noAuthDialog.setShowNoAuthDialog(true);
    }

    festivalEditionAttended(
      {
        attended: !festival_edition.user_attended_festival_edition,
        authToken,
        slug: festival_edition.slug,
      },
      {
        onSettled: () => {
          queryClient.refetchQueries([
            {
              queryKey: ["festival_edition", festival_edition.slug, user?.uid],
            },
            {
              queryKey: ["festivals-upcoming", user?.uid],
            },
          ]);
        },
      },
    );
  };

  return (
    <Box position="relative">
      {children}

      <Box position="absolute" bottom={16} right={8}>
        <Fab
          size="small"
          aria-label="options"
          onClick={handleToggleFestivalEditionAttended}
        >
          {festival_edition.user_attended_festival_edition ? (
            <CheckIcon />
          ) : (
            <AddIcon />
          )}
        </Fab>
      </Box>
    </Box>
  );
};
