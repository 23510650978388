import { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

export const FirebaseAuthAction = () => {
	const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
  	if (mode === 'resetPassword' && oobCode) {
	  	return navigate(`/forgot-password/${oobCode}`);
	  }
	},[mode]);

	return null;
};
