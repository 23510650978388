import { useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { LayoutContext } from "views/layout/layout";

import { UserGravatar } from "views/user/components/user-header/components/user-gravatar/user-gravatar";
import { UserUsername } from "views/user/components/user-header/components/user-username/user-username";
import { UserFollowButton } from "views/user/components/user-header/components/user-follow-button/user-follow-button";
import { UserFollowCount } from "views/user/components/user-header/components/user-follow-count/user-follow-count";

export const UserHeader = ({ user }) => {
  const layoutContext = useContext(LayoutContext);

  return (
    <>
      <Box mb={2}>
        <Grid container alignItems="top" spacing={2}>
          <Grid item>
            <UserGravatar user={user} />
          </Grid>
          <Grid item xs>
            <Box mb={2}>
              <UserUsername user={user} />
            </Box>

            <UserFollowCount user={user} />
          </Grid>
          <Grid item>
            <UserFollowButton userData={user} />
          </Grid>
        </Grid>
      </Box>

      <Box p={2}>
        <Divider />
      </Box>
    </>
  );
};
