import dayjs from "dayjs";

export const getDateSpanString = ({ start_date, end_date }) => {
  const edition_start_date = dayjs(start_date);
  const edition_end_date = dayjs(end_date);

  const edition_different_month =
    edition_start_date.month() !== edition_end_date.month();
  const edition_multiple_days = !edition_start_date.isSame(
    edition_end_date,
    "day",
  );

  return edition_multiple_days
    ? `${edition_start_date.format("MMMM D")} - ${edition_end_date.format(edition_different_month ? "ddd, MMMM D, YYYY" : "D, YYYY")}`
    : edition_start_date.format("dddd, MMMM D, YYYY");
};
