import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { ArtistImage } from "views/artist/components/artist-image/artist-image";
import ReactCountryFlag from "react-country-flag";
import { ListOptions } from "views/components/grid-or-list/grid-or-list";

import dayjs from "dayjs";

import { FestivalEditionArtistUserActions } from "views/components/user-actions/festival-edition-artist-user-actions/festival-edition-artist-user-actions";

export const ArtistRow = ({
  artist,
  festival_edition_slug,
  user_slug,
  variant,
}) => {
  const artistCountryCode =
    artist.services?.musicbrainz?.area?.["iso-3166-1-codes"]?.[0] ||
    artist.services?.musicbrainz?.area?.["iso-3166-2-codes"]?.[0];
  const artistCountryName = artist.services?.musicbrainz?.area?.name;

  const musicbrainzGenres = artist.services?.musicbrainz?.genres
    ?.sort((a, b) => b.count - a.count)
    .slice(0, 3);
  const spotifyGenres = artist.services?.spotify?.genres;

  if (variant === ListOptions.Grid) {
    return (
      <>
        <Link to={`/artist/${artist.slug}`}>
          <Box mb={1}>
            <ArtistImage
              artist={artist}
              artistCountryCode={artistCountryCode}
              artistCountryName={artistCountryName}
            />
          </Box>
          <b>{artist.name}</b>
          {artist.artist_act_name && (
            <>
              <br />
              <i>{artist.artist_act_name}</i>
            </>
          )}
        </Link>

        <FestivalEditionArtistUserActions
          artist={artist}
          festival_edition_slug={festival_edition_slug}
          user_slug={user_slug}
        />

        {/*<div>
          <i>
            <small>
              {musicbrainzGenres?.length > 0
                ? musicbrainzGenres?.map((genre, i) => {
                    return (
                      genre.name +
                      (i === musicbrainzGenres.length - 1 ? "" : ", ")
                    );
                  })
                : spotifyGenres?.map((genre, i) => {
                    return genre + (i === spotifyGenres.length - 1 ? "" : ", ");
                  })}
            </small>
          </i>
        </div>*/}
        {/*<div>{artist.services?.spotify?.popularity}</div>*/}
        {/*<div>
          <small>{dayjs(artist.date).format("dddd")}</small>
        </div>*/}
        {/*<div>
        {artistCountryCode} - {artistCountryName}
      </div>*/}
        {/*<ToggleUserFestivalEditionArtist
        artist={artist.slug}
        attended={artist.user_attended_festival_edition_artist}
        slug={festivalEditionSlug}
      />*/}
      </>
    );
  }

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Link to={`/artist/${artist.slug}`}>
            <Box alignItems="center" display="flex">
              <Box display="inline-block" maxWidth={50} mr={2}>
                <ArtistImage
                  artist={artist}
                  artistCountryCode={artistCountryCode}
                  artistCountryName={artistCountryName}
                />
              </Box>
              <Box display="block">
                <b>{artist.name}</b>
                {artist.artist_act_name && (
                  <>
                    <br />
                    <i>{artist.artist_act_name}</i>
                  </>
                )}
              </Box>
            </Box>
          </Link>
        </Grid>

        <Grid item>
          <FestivalEditionArtistUserActions
            artist={artist}
            festival_edition_slug={festival_edition_slug}
            user_slug={user_slug}
          />
        </Grid>
      </Grid>

      <Box my={2}>
        <Divider light variant="middle" />
      </Box>
    </Box>
  );
};
