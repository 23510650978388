import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";

import { FestivalUserActions } from "views/components/user-actions/festival-user-actions/festival-user-actions";

import { FestivalImage } from 'views/festival/components/festival-image/festival-image';

export const FestivalHeader = ({ festival, link }) => {
  const festivalName = <Typography variant="h3">{festival?.name}</Typography>;

  if (!festival) {
    return null;
  }

  return (
    <Stack alignItems="center" direction="column" spacing={1}>
      <FestivalImage festival={festival} />

      {link ? <Link to={`/festival/${festival?.slug}`}>{festivalName}</Link> : festivalName}

      {/*<FestivalUserActions festival={festival} />*/}
    </Stack>
  );
};
