import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import { UserAvatar } from "views/components/user-avatar/user-avatar";

export const UserRow = ({ justifyContent, max, size, users, usersCount }) => {
  return (
    <Box display="flex" justifyContent={justifyContent}>
      <AvatarGroup max={max ?? 8} total={usersCount}>
        {users?.map((user) => {
          return <UserAvatar key={user.email_sha256} size={size} user={user} />;
        })}
      </AvatarGroup>
    </Box>
  );
};
