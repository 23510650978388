import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { app } from "configs/firebase/firebase-config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from "auth/auth-provider";
import { Router } from "./routes/router";

import ReactGA from "react-ga4";

ReactGA.initialize("G-ZRR6LC5GV8");

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
