import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export const ForgotPassword = () => {
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);

	const authentication = getAuth();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const email = event.target.email.value;

		try {
			sendPasswordResetEmail(authentication, email).then(
				() => {
					setShowSuccessMessage(true);
				},
			);
		} catch (error) {
			setShowErrorMessage(true);
		}
	};

	return (
		<form id="sign-up" onSubmit={handleSubmit}>
			{showErrorMessage && (
        <Box mb={1}>
          <Alert severity="error">There was an error, please try again.</Alert>
        </Box>
      )}

      {showSuccessMessage && (
        <Box mb={1}>
          <Alert severity="success">Reset password link sent! Please click on the link in the email.</Alert>
        </Box>
      )}

			<Box display="inline" mr={2}>
				<TextField
					label="Email"
					name="email"
					type="text"
					size="small"
					sx={{ maxWidth: 150 }}
					variant="standard"
					required
				/>
			</Box>

			<Button type="submit">Send Reset Password Email</Button>
		</form>
	);
};
