import { mfestsApi } from "api/mfests_api";

export const createUser = async ({ email, firebase_uid }) => {
  mfestsApi.post({ url: 'user/create', body: { email, firebase_uid } });
  return null;
}

export const getUser = async ({ authToken, slug }) => {
  const data = await mfestsApi.get({ authToken, url: `user/${slug}` });
  return data.data?.[0] || null;
};

export const putUserFollow = async ({ follow, authToken, user }) => {
  const data = await mfestsApi.put({
    authToken,
    body: { user, follow },
    url: `user/follow`,
  });
  return data.data[0];
};