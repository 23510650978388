import { useAuth } from "auth/use-auth";
import { useQuery } from "@tanstack/react-query";
import { queryFestivalsUpcoming } from "api/query/query-festivals-upcoming";

import { FestivalsRow } from "views/components/festivals-row/festivals-row";

import { Helmet } from "react-helmet";

export const loader =
  ({ queryClient, user }) =>
  async () => {
    const query = queryFestivalsUpcoming({ user });

    return queryClient.ensureQueryData(query);
  };

export default function Index() {
  const { user } = useAuth();
  const { data: festivals } = useQuery(queryFestivalsUpcoming({ user }));

  const metaTitle = `mfests.com - music festivals`;
  const metaDescription = `music festivals. artists. fans.`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
      </Helmet>

      <FestivalsRow
        festivals={festivals}
        showActions
        showDates
        showFestivalImage
        showMonthDividers
        showYearDividers
      />
    </>
  );
}
