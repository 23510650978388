import ErrorPage from "views/error/error_page";
import { Layout } from 'views/layout/layout';
// import { SignIn } from 'views/auth/sign-in/sign-in';
// import { SignUp } from 'views/auth/sign-up/sign-up';
import { ForgotPassword } from 'views/auth/forgot-password/forgot-password';
import { ForgotPasswordReset } from 'views/auth/forgot-password/forgot-password-reset';
import { FirebaseAuthAction} from 'views/auth/firebase/action';

export const routesAuth = [
  /*{
    path: "/sign-in",
    element: <Layout><SignIn /></Layout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-up",
    element: <Layout><SignUp /></Layout>,
    errorElement: <ErrorPage />,
  },*/
  {
    path: "/authenticate",
    element: <Layout><FirebaseAuthAction /></Layout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password/:code",
    element: <Layout><ForgotPasswordReset /></Layout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    element: <Layout><ForgotPassword /></Layout>,
    errorElement: <ErrorPage />,
  }
];
