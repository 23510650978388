import { useEffect, useState } from "react";
import { useAuth } from "auth/use-auth";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { queryUser } from "api/query/query-user";

import { Helmet } from "react-helmet";

import { UserHeader } from "views/user/components/user-header/user-header";
import { UserTabs } from "views/user/components/user-tabs/user-tabs";
import { UserFestivals } from "views/user/components/user-festivals/user-festivals";
import { UserArtists } from "views/user/components/user-artists/user-artists";

import { createContext } from "react";
import { sha256 } from "js-sha256";

export const UserContext = createContext();

export const loader =
  ({ queryClient, user }) =>
  async ({ params }) => {
    const query = queryUser({ slug: params.slug, user });

    return queryClient.ensureQueryData(query);
  };

export const User = () => {
  const { user } = useAuth();
  const params = useParams();
  const { data } = useQuery(queryUser({ slug: params.slug, user }));

  const [allArtists, setAllArists] = useState([]);
  const [artistsFavorite, setArtistsFavorite] = useState([]);
  const [artistsSeen, setArtistsSeen] = useState([]);
  const [artistsWatchlist, setArtistsWatchlist] = useState([]);

  const email_sha256 = user?.email ? sha256(user?.email) : undefined;
  const isAuthUser = data.email_sha256.localeCompare(email_sha256) === 0;
  const isUserSignedIn = !!user;

  const metaTitle = `${data.username || data?.email_sha256} | mfests.com - music festivals`;
  const metaDescription = `${data.username || data?.email_sha256} music festivals and favorite artists`;

  const tabData = [
    {
      title: `${data.festival_editions.length > 0 ? data.festival_editions.length + " " : ""}Festivals`,
      content: <UserFestivals festivals={data.festival_editions} />,
    },
    {
      title: `${allArtists.length > 0 ? allArtists.length + " " : ""}Artists`,
      content: (
        <UserArtists
          artists={allArtists}
          favorite_artists={artistsFavorite}
          seen_artists={artistsSeen}
          watchlist_artists={artistsWatchlist}
        />
      ),
    },
  ];

  useEffect(() => {
    const allArtists = Array.from(
      [
        ...data.favorite_artists,
        ...data.seen_artists,
        ...data.watchlist_artists,
      ]
        .reduce((acc, artist) => {
          return acc.set(artist.slug, artist);
        }, new Map())
        .values(),
    );

    setAllArists(allArtists);
    setArtistsFavorite(data.favorite_artists);
    setArtistsSeen(data.seen_artists);
    setArtistsWatchlist(data.watchlist_artists);
  }, [data.favorite_artists, data.seen_artists, data.watchlist_artists]);

  if (!data) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
      </Helmet>

      <UserContext.Provider value={{ ...data, isAuthUser, isUserSignedIn }}>
        <UserHeader user={data} />
        <UserTabs tabs={tabData} />
      </UserContext.Provider>
    </>
  );
};
