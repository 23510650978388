import { useContext } from "react";
import { UserContext } from "views/user/user";

import { GravatarQuickEdit } from "views/user/settings/components/gravatar-quick-edit/gravatar-quick-edit";
import { UserAvatar } from "views/components/user-avatar/user-avatar";

export const UserGravatar = ({ user }) => {
  const userContext = useContext(UserContext);

  const userAvatar = (
    <UserAvatar
      noLink
      size="large"
      user={user}
    />
  );

  if (!userContext.isAuthUser) {
    return userAvatar;
  }

  return <GravatarQuickEdit>{userAvatar}</GravatarQuickEdit>;
};
