import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { UserRow } from "views/festival-edition/components/user-row/user-row";

export const UserFollowCount = ({ user }) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={3}
    >
      <Stack direction="column">
        Following
        <br />
        <UserRow
          max={5}
          size="small"
          users={user.following}
          usersCount={user.following?.length}
        />
      </Stack>

      <Stack direction="column">
        Followers
        <br />
        <UserRow
          max={5}
          size="small"
          users={user.followers}
          usersCount={user.followers?.length}
        />
      </Stack>
    </Stack>
  );
};
