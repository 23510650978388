import { useAuth } from "auth/use-auth";
import { useQueryClient } from "@tanstack/react-query";
import { usePutFestivalEditionAttended } from "api/query/put/use-put-festival-edition-attended";
import Button from "@mui/material/Button";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export const ButtonWereYouThere = ({ festival_edition }) => {
  const { authToken, noAuthDialog, user } = useAuth();
  const queryClient = useQueryClient();
  const { mutate: festivalEditionAttended } = usePutFestivalEditionAttended();

  const getButtonText = () => {
    const attended = festival_edition?.user_attended_festival_edition;

    const start_date = dayjs(festival_edition?.start_date);
    const end_date = dayjs(festival_edition?.end_date);

    const isBefore = dayjs().isBefore(start_date);
    const isBetween = dayjs().isBetween(start_date, end_date, "day", "[]");

    if (isBetween) {
      return attended ? "You're here!" : "Are you here?";
    }

    if (isBefore) {
      return attended ? "You're going!" : "Are you coming?";
    }

    return attended ? "You were there!" : "Were you there?";
  };

  const handleToggleFestivalEditionAttended = () => {
    if (!authToken) {
      return noAuthDialog.setShowNoAuthDialog(true);
    }

    festivalEditionAttended(
      {
        attended: !festival_edition?.user_attended_festival_edition,
        authToken,
        slug: festival_edition?.slug,
      },
      {
        onSettled: () => {
          queryClient.refetchQueries({
            queryKey: ["festival_edition", festival_edition?.slug, user?.uid],
          });
        },
      },
    );
  };

  return (
    <Button
      fullWidth
      onClick={handleToggleFestivalEditionAttended}
      variant={
        festival_edition?.user_attended_festival_edition
          ? "contained"
          : "outlined"
      }
    >
      {getButtonText()}
    </Button>
  );
};
